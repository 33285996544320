// components
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  home: getIcon('ic_home'),
  group: getIcon('ic_group'),
  user: getIcon('ic_user'),
  school: getIcon('ic_school'),
  accounting: getIcon('ic_accounting'),
  receipt: getIcon('ic_receipt'),
  book: getIcon('ic_book'),
  setting: getIcon('ic_setting'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
};

const navConfig = [
  {
    subheader: 'Dashboard',
    items: [
      { title: 'Home', path: '/apply', icon: ICONS.home },
      // {
      //   title: 'admissions',
      //   path: '/apply/yow',
      //   icon: ICONS.group,
      //   children: [
      //     { title: 'Applicants', path: '/admin/admissions/applicants' },
      //     { title: 'Periods', path: '/admin/admissions/periods' },
      //     { title: 'Workflows', path: '/admin/admissions/workflows' },
      //     { title: 'Reports', path: '/admin/admissions/reports' },
      //   ],
      // },
    ],
  },
];

export default navConfig;