// components
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  home: getIcon('ic_home'),
  group: getIcon('ic_group'),
  user: getIcon('ic_user'),
  chat: getIcon('ic_chat'),
  blog: getIcon('ic_blog'),
  school: getIcon('ic_school'),
  accounting: getIcon('ic_accounting'),
  receipt: getIcon('ic_receipt'),
  book: getIcon('ic_book'),
  setting: getIcon('ic_setting'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
};

const navConfig = [
  {
    subheader: 'Dashboard',
    items: [
      { title: 'Home', path: '/home', icon: ICONS.home },
      { title: 'About Us', path: '/about', icon: ICONS.group },
      { title: 'Contact Us', path: '/contact', icon: ICONS.chat },
    ],
  },
];

export default navConfig;
