// routes
import DefaultRouter from './routes';
// theme
import ThemeProvider from './theme';
// components
import ScrollToTop from './components/ScrollToTop';
import { ProgressBarStyle } from './components/ProgressBar';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import { AuthProvider } from './contexts/AuthContext';
import NotistackProvider from './components/NotistackProvider';
import './global.css'
// ----------------------------------------------------------------------

export default function App() {
  return (
    <MotionLazyContainer>
      <ThemeProvider>
          <NotistackProvider>
            <AuthProvider>
              <ProgressBarStyle />
              <ScrollToTop />
              <DefaultRouter />
            </AuthProvider>
        </NotistackProvider>
      </ThemeProvider>
    </MotionLazyContainer>
  );
}
