// ----------------------------------------------------------------------

function path(root, sublink) {
  // if (section) return `${root}${sublink}`
  return `${root}${sublink}`
}

const ROOTS_DASHBOARD = '/dashboard'
const ROOTS_ADMIN = '/admin'
const ROOTS_APPLY = '/apply'
const ROOTS_STUDENT = '/student'

// for dynamic auth path
const generateAuthPath = section => {
  const ROOTS_AUTH = `/${section}/auth`
  return {
    root: ROOTS_AUTH,
    login: path(ROOTS_AUTH, '/login'),
    register: path(ROOTS_AUTH, '/register'),
    loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
    registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
    verify: path(ROOTS_AUTH, '/verify'),
    resetPassword: path(ROOTS_AUTH, '/reset-password'),
    newPassword: path(ROOTS_AUTH, '/new-password'),
  }
}

// ----------------------------------------------------------------------

export const PATH_APPLY_AUTH = generateAuthPath('apply')
export const PATH_STUDENT_AUTH = generateAuthPath('student')
export const PATH_ADMIN_AUTH = generateAuthPath('admin')

// eslint-disable-next-line consistent-return
export const getAuthPath = section => {
  if (section === 'apply') return PATH_APPLY_AUTH
  if (section === 'student') return PATH_STUDENT_AUTH
  if (section === 'admin') return PATH_ADMIN_AUTH
}

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about',
  contact: '/contact',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
}

export const getHomePath = (pathname) => {
  const path = '/'
  return path
}
