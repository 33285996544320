
import { useState } from 'react';
import { Outlet } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
// hooks
import useSettings from '../hooks/useSettings';
import useResponsive from '../hooks/useResponsive';
import useCollapseDrawer from '../hooks/useCollapseDrawer';
// config
import { HEADER, NAVBAR } from '../config';
//
import NavbarHorizontal from './dashboard/navbar/NavbarHorizontal';

export default function PageHeader() {
  const { collapseClick, isCollapse } = useCollapseDrawer();

  const { themeLayout } = useSettings();

  const isDesktop = useResponsive('up', 'lg');

  const [open, setOpen] = useState(false);

  const verticalLayout = themeLayout === 'vertical';

  const MainStyle = styled('main', {
    shouldForwardProp: (prop) => prop !== 'collapseClick',
  })(({ collapseClick, theme }) => ({
    flexGrow: 1,
    paddingTop: HEADER.MOBILE_HEIGHT,
    paddingBottom: HEADER.MOBILE_HEIGHT,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 16,
      paddingRight: 16,
      paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT,
      paddingBottom: HEADER.DASHBOARD_DESKTOP_HEIGHT,
      width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
      transition: theme.transitions.create('margin-left', {
        duration: theme.transitions.duration.shorter,
      }),
      ...(collapseClick && {
        marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
      }),
    },
  }));


  return (
    <Box
      sx={{
        display: { lg: 'flex' },
        minHeight: { lg: 1 },
      }}
    >

      <NavbarHorizontal isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />

      <MainStyle collapseClick={collapseClick}>
        <Outlet />
      </MainStyle>
    </Box>
      );

}
