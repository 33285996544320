import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import { DashboardLayout } from '../layouts/dashboard';
import { ApplyLayout } from '../layouts/applyLayout';
import PageHeader from '../layouts/PageHeader';
// import LogoOnlyLayout from '../layouts/LogoOnlyLayout';

// components
import LoadingScreen from '../components/LoadingScreen';
import Section from '../components/Section'; // used for updating cognito config

// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense /* fallback={<LoadingScreen isDashboard={pathname.includes('/admin')} />} */>
      <Component {...props} />
    </Suspense>
  );
};


export default function Router() {
  return useRoutes([
    {
      path: '',
      element: <PageHeader />,
      children: [
        { path: '', element: <Navigate to="/home" replace /> },
        { path: 'home', element: <Home /> },
        { path: 'contact', element: <Contact /> },
        { path: 'about', element: <About /> },
      ],
    }, {
      path: 'comingsoon',
      element: <ComingSoon />,
    }
  ]);
}

// Dashboard
const Home = Loadable(lazy(() => import('../pages/Home')));
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
const About = Loadable(lazy(() => import('../pages/About')));
const Contact = Loadable(lazy(() => import('../pages/Contact')));
