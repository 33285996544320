// @mui
import { styled } from '@mui/material/styles';
import { Grid, RadioGroup, CardActionArea, Tooltip } from '@mui/material';
// hooks
import useSettings from '../hooks/useSettings';
import Iconify from './Iconify';
import BoxMask from './settings/drawer/BoxMask';
//

// ----------------------------------------------------------------------

const BoxStyle = styled(CardActionArea)(({ theme }) => ({
  height: 40,
  width: 40,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.text.disabled,
  border: `solid 1px ${theme.palette.grey[500_12]}`,
  borderRadius: Number(theme.shape.borderRadius) * 1.25,
}));

// ----------------------------------------------------------------------

export default function SettingMode() {
  const { themeMode, onChangeMode } = useSettings();

  return (
    <Tooltip title='Toggle Light/Dark'>
      <RadioGroup name="themeMode" value={themeMode} onChange={onChangeMode}>
        <Grid dir="ltr" container>
          {['light', 'dark'].map((mode, index) => {
            const isSelected = themeMode === mode;

            return (
              <Grid key={mode} item xs={6}>
                <BoxStyle
                  sx={{
                    padding: 1,
                    display: mode === themeMode ? 'none' : 'block',
                    bgcolor: mode === 'light' ? 'grey.800' : 'grey.200',
                    ...(!isSelected && {
                      color: 'primary.main',
                      boxShadow: (theme) => theme.customShadows.z20,
                    }),
                    ...(index === 1 && {
                      position: 'relative',
                      right: '100%',
                    }),
                  }}
                >
                  <Iconify icon={index === 0 ? 'ph:sun-duotone' : 'ph:moon-duotone'} width={20} height={20} />
                  <BoxMask value={mode} />
                </BoxStyle>
              </Grid>
            );
          })}
        </Grid>
      </RadioGroup>
    </Tooltip>
  );
}