// import { useTheme } from '@mui/material';

const LogoFull = ({ color }) => {

  /* For light/dark toggle

  const theme = useTheme();
  const colors = {
    primary: theme.palette.light,
  } 
  */

  const fontColor = "#061944"

  return (
    <svg width="305" height="95" viewBox="0 0 305 95" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M79.6787 27.4893V42.5434C82.0112 39.5513 85.2768 38.6162 88.0758 38.6162C94.6068 38.6162 99.1786 42.0759 99.1786 51.9873V66.9479H91.0614V51.9873C91.0614 48.0601 89.0088 46.003 85.7433 46.003C82.3844 46.003 79.5854 48.3406 79.5854 52.8288V66.9479H71.4683V27.4893H79.6787Z" fill={fontColor} />
      <path d="M112.334 55.6339C112.707 58.3455 115.32 60.4961 119.891 60.4961C122.131 60.4961 125.116 60.0286 127.076 58.252L131.647 63.3012C128.662 66.1999 123.903 67.4154 119.705 67.4154C109.628 67.4154 104.124 61.5247 104.124 52.6418C104.124 44.2264 109.815 38.2422 118.865 38.2422C128.288 38.2422 134.54 44.1329 132.767 55.5404H112.334V55.6339ZM125.489 49.7432C125.303 46.6575 122.224 45.255 118.865 45.255C115.6 45.255 113.174 46.9381 112.334 49.7432H125.489Z" fill={fontColor} />
      <path d="M159.637 39.0835H167.195C167.195 48.3404 167.195 57.6908 167.195 66.9477H159.917L159.451 63.4881C157.585 66.3867 153.573 67.5087 150.867 67.6022C142.563 67.6957 136.499 62.2725 136.499 53.1091C136.499 43.8522 142.843 38.429 151.054 38.616C154.506 38.616 157.771 39.9251 159.264 42.4497L159.637 39.0835ZM144.616 53.0156C144.616 57.5973 147.882 60.3089 151.894 60.3089C156.465 60.3089 159.264 56.7558 159.264 53.2961C159.264 49.2755 156.745 45.6288 151.894 45.6288C147.882 45.6288 144.616 48.3404 144.616 53.0156Z" fill={fontColor} />
      <path d="M182.216 27.4893V66.8544H174.192V27.4893H182.216Z" fill={fontColor} />
      <path d="M200.223 31.416V39.1768H207.221V45.9091H200.13V56.3816C200.13 59.2802 201.716 60.1217 203.302 60.1217C204.235 60.1217 205.821 59.9347 206.661 59.4672L208.527 66.1995C206.474 66.9475 204.888 67.3215 202.649 67.415C196.678 67.6955 192.106 65.0774 192.106 56.3816V45.9091H187.161V39.1768H192.106V32.3511L200.223 31.416Z" fill={fontColor} />
      <path d="M220.936 27.4893V42.5434C223.269 39.5513 226.534 38.6162 229.333 38.6162C235.864 38.6162 240.436 42.0759 240.436 51.9873V66.9479H232.319V51.9873C232.319 48.0601 230.266 46.003 227.001 46.003C223.642 46.003 220.843 48.3406 220.843 52.8288V66.9479H212.726V27.4893H220.936Z" fill={fontColor} />
      <path d="M254.991 27.4893V49.9302L263.201 39.0837H272.718V39.5513L262.548 52.0808L274.304 66.1999V66.8544H264.601L254.991 54.6054V66.8544H246.874V27.4893H254.991Z" fill={fontColor} />
      <path d="M274.584 53.0163C274.584 44.601 280.555 38.4297 289.792 38.4297C299.029 38.4297 305 44.601 305 53.0163C305 61.4317 299.215 67.5094 289.792 67.5094C280.369 67.5094 274.584 61.4317 274.584 53.0163ZM296.79 53.0163C296.79 49.5567 294.644 45.723 289.792 45.723C284.94 45.723 282.794 49.5567 282.794 53.0163C282.794 56.476 284.847 60.3096 289.792 60.3096C294.737 60.3096 296.79 56.663 296.79 53.0163Z" fill={fontColor} />
      <path d="M34.2413 40.5811C31.2557 44.6017 29.1098 49.2769 28.0835 54.3261H20.5261C23.5117 50.3055 25.6577 45.6303 26.684 40.5811H34.2413Z" fill="#92B4FF" />
      <path d="M54.8608 27.4902V41.7028C48.9829 43.7599 44.3178 48.4351 42.2652 54.3259C41.5188 56.4764 41.0523 58.814 41.0523 61.2451C41.0523 63.6762 41.4255 66.0139 42.2652 68.1644C44.3178 74.0552 48.9829 78.7304 54.8608 80.7875V95.0001C49.8226 93.9715 45.1576 91.8209 41.1456 88.8288C38.5332 86.8652 36.2007 84.5276 34.2414 81.9095C31.2558 77.8888 29.1098 73.2137 28.0835 68.1644C27.617 65.9203 27.4304 63.6762 27.4304 61.2451C27.4304 58.9075 27.7103 56.57 28.0835 54.3259C29.1098 49.2766 31.2558 44.6015 34.2414 40.5808C36.2007 37.9627 38.5332 35.6251 41.1456 33.6615C45.1576 30.7629 49.8226 28.5188 54.8608 27.4902Z" fill="#4C7BF4" />
      <path d="M27.4304 33.7549C27.4304 36.0925 27.1505 38.4301 26.7773 40.6742C25.751 45.7234 23.6051 50.4921 20.6195 54.4193C18.6601 57.0374 16.3276 59.375 13.7152 61.3386C9.70328 64.3307 5.03824 66.4813 0 67.5098V53.2972C5.87795 51.2402 10.543 46.565 12.5956 40.6742C13.342 38.5236 13.8085 36.186 13.8085 33.7549C13.8085 31.3238 13.4353 28.9862 12.5956 26.8356C10.543 20.9449 5.87795 16.2697 0 14.2126V0C5.03824 1.02854 9.70328 3.17914 13.7152 6.17126C16.3276 8.13485 18.6601 10.4724 20.6195 13.0905C23.6051 17.1112 25.751 21.7864 26.7773 26.8356C27.1505 29.0797 27.4304 31.3238 27.4304 33.7549Z" fill="#00D0EE" />
    </svg>

  )
}

export default LogoFull;