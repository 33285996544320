/* eslint no-lone-blocks: 0 */

import PropTypes from 'prop-types';
import { React, memo, useState } from 'react';
// @mui
import { Box, Stack, IconButton, Menu, MenuItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
//
import NavList from './NavList';
import SettingsModeToggle from '../../SettingsModeToggle';
import useSettings from '../../../hooks/useSettings';
import Image from '../../Image';
import LinkText from '../../LinkText';

// ----------------------------------------------------------------------

const hideScrollbar = {
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
  overflowY: 'scroll',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
};

NavSectionHorizontal.propTypes = {
  navConfig: PropTypes.array,
};

function NavSectionHorizontal({ navConfig }) {
  const { themeMode } = useSettings();
  const logo = themeMode === 'light' ? "assets/genzai_icon.png" : "assets/genzai_icon_white.png"
  const logoText = themeMode === 'light' ? "assets/genzai_text.png" : "assets/genzai_text_white.png"

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const HamburgerMenuLinks = () => <Menu
    id="demo-positioned-menu"
    aria-labelledby="demo-positioned-button"
    anchorEl={anchorEl}
    open={open}
    onClose={handleClose}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    sx={{ left: 0, right: 0 }}

  >
    {navConfig.map((group) => (
      <Stack key={group.subheader} direction="column" flexShrink={0} rowGap={1} sx={{ px: 2, pt: 1 }}>
        {group.items.map((list) => (
          <Box key={list.title + list.path} onClick={() => handleClose()} >
            <NavList key={list.title + list.path} data={list} depth={1} hasChildren={!!list.children} />
          </Box>
        ))}
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <SettingsModeToggle />
        </Box>
      </Stack>
    ))}


  </Menu>

  return (
    <Stack direction="row" justifyContent="center" sx={{ bgcolor: 'background.neutral', borderRadius: 1, px: 0.5, minHeight: { xs: '52px', md: '48px' }, position: 'relative' }}>

      {/* LOGO */}
      <Stack
        direction="row"
        sx={{
          ...hideScrollbar,
          py: 1,
          position: "absolute",
          display: 'flex',
          left: 20,
          top: 0, bottom: 0,
        }}>
        <LinkText to="/">
          <Box sx={{ maxHeight: '36px' }}>
            <img className="logoText-style" src={logoText} alt="logo" />
            <img className="logo-style" src={logo} alt="logo" />
          </Box>
        </LinkText>
      </Stack>

      {/* Hamburger menu */}
      <IconButton
        edge="start"
        color="inherit"
        id="demo-positioned-button"
        aria-label="menu"
        aria-controls={open ? 'demo-positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{ display: { xs: 'flex', md: 'none' }, position: 'absolute', top: 0, bottom: 0, right: 10 }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} color="text.primary">
          <MenuIcon fontSize='large' />
        </Box>
      </IconButton>

      <HamburgerMenuLinks />

      {/* Links */}
      <Stack direction="row" sx={{ ...hideScrollbar, py: 1, pl: { xs: 10, sm: 0 }, display: { xs: 'none', md: 'flex' } }}>
        {navConfig.map((group) => (
          <Stack key={group.subheader} direction="row" flexShrink={0}>
            {group.items.map((list) => (
              <NavList key={list.title + list.path} data={list} depth={1} hasChildren={!!list.children} />
            ))}
          </Stack>
        ))}
      </Stack>

      {/* Light/Dark mode toggle */}
      <Stack
        direction="row"
        sx={{
          ...hideScrollbar, pt: 0.5,
          display: { xs: 'none', md: 'flex' },
          position: { sm: "absolute" },
          right: 40,
        }}>
        <SettingsModeToggle />
      </Stack>

    </Stack>
  );
}

export default memo(NavSectionHorizontal);
