import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
// @mui
import { styled } from '@mui/material/styles';
import { Box, IconButton } from '@mui/material';
//
import Image from '../Image';
import RejectionFiles from './RejectionFiles';
import BlockContent from './BlockContent';
import Iconify from '../Iconify';

// ----------------------------------------------------------------------

const DropZoneStyle = styled('div')(({ theme }) => ({
  outline: 'none',
  overflow: 'hidden',
  position: 'relative',
  padding: theme.spacing(5, 1),
  borderRadius: theme.shape.borderRadius,
  transition: theme.transitions.create('padding'),
  backgroundColor: theme.palette.background.neutral,
  border: `1px dashed ${theme.palette.grey[500_32]}`,
  '&:hover': { opacity: 0.72, cursor: 'pointer' },
}));

// ----------------------------------------------------------------------

UploadSingleFile.propTypes = {
  error: PropTypes.bool,
  file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  helperText: PropTypes.node,
  sx: PropTypes.object,
};

export default function UploadSingleFile({ error = false, file, helperText, sx, preview, handleRemove, disabled, ...other }) {
  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    multiple: false,
    disabled,
    ...other,
  });

  return (
    <Box sx={{ width: '100%', ...sx }}>
      <DropZoneStyle
        {...getRootProps()}
        sx={{
          ...(isDragActive && { opacity: 0.72 }),
          ...((isDragReject || error) && {
            color: 'error.main',
            borderColor: 'error.light',
            bgcolor: 'error.lighter',
          }),
          ...((file || disabled) && {
            padding: '12% 0',
            '&:hover': { opacity: 1, cursor: 'default' }
          }),
        }}
      >
        <input {...getInputProps()} />

        <BlockContent file={file || preview} />

        {(file || preview) && (<>
          <Image
            alt="file preview"
            src={typeof file === 'string' ? file : typeof preview === 'string' ? preview : file.preview} // eslint-disable-line no-nested-ternary 
            sx={{
              top: 8,
              left: 8,
              borderRadius: 1,
              position: 'absolute',
              width: 'calc(100% - 16px)',
              height: 'calc(100% - 16px)',
            }}
          />

          {/* remove file button */}
          {file && handleRemove && <IconButton
            onClick={(e) => {
              e.stopPropagation()
              handleRemove()
            }}
            size='small'
            sx={{
              top: 25,
              right: 25,
              bgcolor: 'error.main',
              color: 'error.contrastText',
              position: 'absolute',
              '&:hover': { color: 'error.main' }
            }}
          >
            <Iconify icon={'eva:close-fill'} width={15} height={15} />
          </IconButton>}
        </>)}
      </DropZoneStyle>

      {fileRejections.length > 0 && <RejectionFiles fileRejections={fileRejections} />}

      {helperText && helperText}
    </Box>
  );
}
