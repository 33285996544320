import { Link as RouterLink } from 'react-router-dom';
import { Box, Link, Typography } from '@mui/material';

export default function LinkText({ children, to, sx, ...other }) {
    return (
        <Link
            variant="body2"
            component={RouterLink}
            to={to || '#'}
            sx={{
                lineHeight: 2,
                display: 'flex',
                alignItems: 'center',
                color: 'text.primary',
                '& > div': { display: 'inherit' },
                ...sx
            }}
            {...other}
        >
            {children}
        </Link>
    )
}