import axios from 'axios';
// config
import { Auth } from 'aws-amplify'
import { HOST_API } from '../config';

// ----------------------------------------------------------------------
const axiosInstance = axios.create({
  baseURL: HOST_API,
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);


axiosInstance.interceptors.request.use(async (config) => {
  try {
    // console.log("here inside2")
    // console.log(JSON.stringify(config,null,4))
      const session = await Auth.currentSession();
      // User is logged in. Set auth header on all requests
      config.headers.common.Authorization = `Bearer ${session.idToken.jwtToken}`
      return await Promise.resolve(config);
  } catch (e) {
    console.log(e)
      return Promise.resolve(config);
  }
}, (error) => Promise.reject(error));

export default axiosInstance;
