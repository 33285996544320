import PropTypes from 'prop-types';
import { createContext, useState, useEffect } from 'react';
import { Hub, Auth } from 'aws-amplify'

import axios from '../utils/axios'
import { HOST_API } from '../config';

/* eslint-disable camelcase */
export const cognitoConfig = ({ domain, aws_cognito_identity_pool_id, aws_user_pools_id, aws_user_pools_web_client_id, redirectSignIn, redirectSignOut }) => ({
        aws_project_region: 'ap-southeast-1',
        aws_cognito_identity_pool_id,
        aws_cognito_region: 'ap-southeast-1',
        aws_user_pools_id,
        aws_user_pools_web_client_id,
        oauth: {
            domain,
            scope: [
                'phone',
                'email',
                'openid',
                'profile',
                'aws.cognito.signin.user.admin',
            ],
            redirectSignIn,
            redirectSignOut,
            responseType: 'code',
        },
        federationTarget: 'COGNITO_USER_POOLS',
    })

export const AuthContext = createContext({
    isAuthenticated: false,
    setIsAuthenticated: () => { },
    isInitialized: true,
    setIsInitialized: () => { },
    isNewPasswordRequired: false,
    setIsNewPasswordRequired: () => { },
    user: null,
    setUser: () => { },
    config: {},
    setConfig: () => { },
    prevOthersConfig: {},
    setPrevOthersConfig: () => { },
    section: null,
    setSection: () => { },
    login: () => Promise.resolve(),
    logout: () => Promise.resolve(),
    signup: () => Promise.resolve(),
    validate: () => Promise.resolve(),
    forgotPassword: () => Promise.resolve(),
    forgotPasswordSubmit: () => Promise.resolve(),
    completeNewPassword: () => Promise.resolve(),
    register: () => Promise.resolve(),
    whoAmI: null,
    setWhoAmI: () => { },
})



export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [config, setConfig] = useState({});
    const [prevOthersConfig, setPrevOthersConfig] = useState(null)
    const [isInitialized, setIsInitialized] = useState(false)
    const [isAuthenticated, setIsAuthenticated] = useState(false)
    const [isNewPasswordRequired, setIsNewPasswordRequired] = useState(false)
    const [section, setSection] = useState(null)
    const [whoAmI, setWhoAmI] = useState(null)

    const getCognitoConfig = (domain, cb) => {
        const fullPath = `${domain}/${section}`
        const fullPathWithProtocol = `https://${fullPath}`
        const redirectUrl = process.env.REACT_APP_ENV !== "local" ? fullPathWithProtocol : `https://localhost:3000/${section}`
        const config = {
            method: 'get',
            url: `/v1/auth/settings?host=${domain}/${section}`,
        };

        axios(config).then((response) => {
            console.log(JSON.stringify(response.data, null, 4));


            const authConfig = cognitoConfig({
                ...response.data,
                redirectSignIn: redirectUrl,
                redirectSignOut: redirectUrl
            })
            delete authConfig.domain;
            authConfig.oauth.domain = response.data.domain;
            console.log(JSON.stringify(authConfig, null, 4));
            setConfig(authConfig);
            setPrevOthersConfig(authConfig);
            cb();
        })
            .catch((error) => {
                console.log(error);
            });
    };

    useEffect(() => {
        if (section !== null) {
            const domain = process.env.REACT_APP_ENV !== "local" ? window.location.hostname : process.env.REACT_APP_DOMAIN
            getCognitoConfig(domain, () => { })
            setUser(null)
        }
    }, [section])

    useEffect(() => {
        Hub.listen('auth', async (data) => {
            switch (data.payload.event) {
                case 'signIn':
                    console.log('user signed in');
                    setUser(await Auth.currentSession());
                    setIsAuthenticated(true)
                    break;
                case 'signUp':
                    console.log('user signed up');
                    break;
                case 'signOut':
                    console.log('user signed out');
                    setUser(null)
                    setIsAuthenticated(false)
                    break;
                case 'signIn_failure':
                    console.log('user sign in failed');
                    break;
                case 'configured':
                    console.log('the Auth module is configured');
                    break;
                default:
                    setIsInitialized(true);
            }
        })
    }, []);

    useEffect(() => {
        async function getUser() {
            if (Object.keys(config).length !== 0) {
                Auth.configure(config)
                try {
                    setUser(await Auth.currentSession())
                    setIsAuthenticated(true)
                } catch {
                    setUser(null)
                    setIsAuthenticated(false)
                }

            }
        };
        getUser()
    }, [config])

    useEffect(() => {
        if (user) getAuthMe(user)
        else setWhoAmI(null)
    }, [user])

    const getAuthMe = async (user) => {
        const response = await axios.get(`${HOST_API}/v1/auth/me`)
        setWhoAmI(response.data)
    }
    console.log('whoAmI', whoAmI)

    const login = async (username, password) => {
        const result = await Auth.signIn(username, password);
        console.log(result.challengeName)
        switch (result.challengeName) {
            case "NEW_PASSWORD_REQUIRED":
                setIsNewPasswordRequired(true)
                sessionStorage.setItem('confirm-new-password', username);
                setUser(result)
                break;
            default:
                break;
        }
    }
    const logout = () => {
        Auth.signOut()
    }
    const forgotPassword = async (username) => {
        await Auth.forgotPassword(username)
    }
    const forgotPasswordSubmit = async (username, code, password) => {
        await Auth.forgotPasswordSubmit(username, code, password)
    }

    const completeNewPassword = async (username, password) => {
        await Auth.completeNewPassword(username, password)
        setIsNewPasswordRequired(false)
    }
    const register = async (data) => {
        // mock api call
        await new Promise(resolve => setTimeout(resolve, 1000)) // add 1 sec delay
        const result = `do handleRegister logic\n formData: ${JSON.stringify(data)}`
        return result
    }


    const value = {
        user,
        setUser,
        config,
        setConfig,
        prevOthersConfig,
        setPrevOthersConfig,
        isAuthenticated,
        setIsAuthenticated,
        isInitialized,
        setIsInitialized,
        isNewPasswordRequired,
        setIsNewPasswordRequired,
        section,
        setSection,
        login,
        logout,
        forgotPassword,
        forgotPasswordSubmit,
        register,
        completeNewPassword,
        whoAmI,
    }
    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

AuthProvider.propTypes = {
    children: PropTypes.node,
};